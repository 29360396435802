

.drawerBoxPresentation {
    background-color: var(--main-winahost-color);
    min-width: 100vw;
    overflow-y: auto;
}

@media (max-height: 865px) {
    .drawerBoxPresentation {
        height: 100%;
    }
}


.drawerContainerButton {
    justify-content: flex-end;
    width: 93vw;
}